.site-layout-background {
  /*background: #fff;*/
}

/* fix padding for tabs inside card title */
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 12px 0;
}
[data-theme='dark'] .site-layout-sub-header-background {
  background: #141414;
}
