.supplierHistory .ant-timeline-item-content,
.supplierHistory .ant-timeline-item-content .ant-card {
  /* background: #fafafa;*/
}
.supplierHistory .ant-timeline-item-content .ant-card-body {
  padding: 12px 12px 0px 12px;
}

.supplierHistory .ant-card-actions > li {
  margin: 0;
}
