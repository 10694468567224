.comment {
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  padding: 0px 5px;
}

.myComment {
  text-align: right;
}

.ant-comment-inner {
  padding: 2px 0;
}
